<template>
  <Article class="outer-container"
           header-text="ux design"
           :header-image="cover"
           section-type-name="ux design"
           title="Sentinel Design System"

  >
    <template v-slot:body>
      <div class="intro">
        <Role :roles="['UI Designer', 'UX Designer']" />
        <Section :is-styled="true" :body="stage1Body" :title="stage1Title" :is-sub-section="false"/>
      </div>
      <Row>
        <template v-slot:1>
          <StagedFigureImage
              :src="colorPallete"
              alt="color pallete"
              :is-on-dark="true"
              :size="100"
              cap="Primary and secondary colors for the system. These colors are broken into tints and shades which make them robust to allow for a diverse set of use-cases"
          />
        </template>
        <template v-slot:2>
          <StagedFigureImage
              :src="typeScale"
              alt="Typescale that illustrates the gradation of sizing and spacing of the typography"
              cap="Typescale that illustrates the gradation of sizing and spacing of the typography"
              :is-on-dark="true"
              :size="100"
          />
        </template>
        <template v-slot:3>
          <StagedFigureImage
              :src="spaceScale"
              alt="Spacing scale. This sets the foundation of how elements are placed in relation to each other."
              cap="Spacing scale. This sets the foundation of how elements are placed in relation to each other."
              :is-on-dark="true"
              :size="100"
          />
        </template>
      </Row>
      <Section :is-styled="true" :title="stage2Title" :body="stage2Body" />
      <StagedFigureImage
          :src="columns"
          alt="The number of columns taken up by content cards relative to the overall screen size"
          cap="The number of columns taken up by content cards relative to the overall screen size"
          :is-on-dark="true"
          :size="100"
      />
      <Section :is-styled="true" :title="stage3Title" :body="stage3Body" />
      <Grid>
        <template v-slot:1>
          <InteractionVideo
              caption="Card-like carosel interaction on mobile for swiping through content"
              :video="carousel"
          />
        </template>
        <template v-slot:2>
          <InteractionVideo
              caption="Bottom sheet audio console pull out on mobile"
              :video="console"
          />
        </template>
      </Grid>
      <Section :is-styled="true" :title="stage4Title" :body="stage4Body" />
      <Section :is-styled="true" :title="stage5Title" :body="stage5Body" />
      <Section  :title="maintainanceTitle" :body="maintainanceBody" />

    </template>
  </Article>
</template>

<script>
import SectionHeader from "../../../components/section_header/SectionHeader.vue";
import {store} from "@/store/store.js";
import {colors} from "@/core/colors.js";
import Step from "@/components/info/desktop/Step.vue";
import Figure from "@/components/info/desktop/Figure.vue";
import InteractionVideo from "@/components/info/InteractionVideo.vue";
import CarouselVideo from "@/assets/videos/design_system/card_carousel_mobile.mp4";
import AudioConsole from "@/assets/videos/design_system/audio_console_mobile_transition.mp4";
import Row from "@/components/info/desktop/figure/row.vue";
import BackToTop from "@/components/controls/button/BackToTop.vue";
import SubtitleBody from "@/components/info/desktop/Subtitle_Body.vue";
import Article from "@/components/info/desktop/Article.vue";
import sdCover from "@/assets/images/design_system/pages_cover.webp"
import StagedFigureImage from "@/components/info/desktop/figure/staged_figure.vue";
import colorPallete from "@/assets/images/design_system/color_pallete.webp"
import typeScale from "@/assets/images/design_system/type_scale.webp"
import spaceScale from "@/assets/images/design_system/space_scale.webp"
import columns from "@/assets/images/design_system/columns.webp"
import Grid from "@/components/info/desktop/figure/grid.vue";
import {projects, uxProjects} from "@/core/data/projects";
import Section from "@/components/info/desktop/Section.vue";
import Role from "@/components/info/desktop/Role.vue";


export default {
  store: store,
  data() {
    return {
      carousel: CarouselVideo,
      console: AudioConsole,
    };
  },
  components: {
    Role,
    Section,
    Grid,
    StagedFigureImage,
    Article,
    SectionHeader,
    Step,
    Figure,
    InteractionVideo,
    Row,
    BackToTop,
    SubtitleBody,
  },
  computed: {
    colorPallete: () => colorPallete,
    typeScale: () => typeScale,
    spaceScale: () => spaceScale,
    columns: () => columns,
    isDark: function () {
      return this.$store.getters.isDark;
    },
    cover: () => sdCover,
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
    overview: () => uxProjects.SDdesignSystem.overview,
    stage1Title: () => uxProjects.SDdesignSystem.details.steps[0].name,
    stage1Body: () => uxProjects.SDdesignSystem.details.steps[0].description,
    stage2Title: () => uxProjects.SDdesignSystem.details.steps[1].name,
    stage2Body: () => uxProjects.SDdesignSystem.details.steps[1].description,
    stage3Title: () => uxProjects.SDdesignSystem.details.steps[2].name,
    stage3Body: () => uxProjects.SDdesignSystem.details.steps[2].description,
    stage4Title: () => uxProjects.SDdesignSystem.details.steps[3].name,
    stage4Body: () => uxProjects.SDdesignSystem.details.steps[3].description,
    stage5Title: () => uxProjects.SDdesignSystem.details.steps[4].name,
    stage5Body: () => uxProjects.SDdesignSystem.details.steps[4].description,
    maintainanceTitle: () => uxProjects.SDdesignSystem.details.verification.subtitle,
    maintainanceBody: () => uxProjects.SDdesignSystem.details.verification.description,
  },
};
</script>

<style scoped>
.intro {
  display: grid;
  gap: 3rem;
}
a:link,
a:focus,
a:visited {
  color: #fa713f;
}

.outer-container {
  width: 100vw;
  margin: 3rem 0 11% 0;
  display: flex;
  flex-flow: column;
}

.header-image {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 19rem;
  right: 0;
  width: 71.25rem;
}

.header {
  width: 100vw;
  height: 34.5rem;
}

.content-container {
  top: 3rem;
}

.pallete {
  background: url("../../../assets/images/design_system/color_pallete.webp") no-repeat;
}

.type-scale {
  background: url("../../../assets/images/design_system/type_scale.webp") no-repeat;
}

.space-scale {
  background: url("../../../assets/images/design_system/space_scale.webp") no-repeat;
}

.cols {
  background: url("../../../assets/images/design_system/columns.webp") no-repeat;
}

.fig {
  width: 56rem;
  background-position: center;
  height: 20rem;
  background-size: contain;
  margin: 0 0 0 2rem;
}

.figure {
  width: 56rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.caption {
  width: 30rem;
}

.caption {
  width: 30rem;
}

.figures {
  flex-flow: column;
}
</style>
